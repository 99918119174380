<template>
    <section>
        <modal ref="modalDetalleProducto" icon="box" titulo="Detalle del producto" no-aceptar no-cancelar tamano="modal-lg">
            <!-- :img="nuevo.imagen" -->
            <div class="row mx-0 justify-center">
                <div class="col-auto">
                    <img :src="nuevo.imagen" width="140" height="140" class="border br-12 cr-pointer obj-cover" @click="getImages()"/>
                </div>
                <div class="col-5">
                    <p class="text-general f-17 f-600">
                        {{ nuevo.nombre }}
                    </p>
                    <div class="row mx-0 text-general my-2 f-600 f-14">
                        {{ separadorNumero(nuevo.precio,$tienda.decimales) }}
                    </div>
                    <div class="row mx-0 my-2 mt-3 f-15">
                        <div class="col-auto text-general pl-0">
                            {{ nuevo.descripcion }}
                        </div>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2 text-general">
                            Presentación:
                        </b>
                        <p>{{ nuevo.presentacion }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2 text-general">
                            Unidad de medida:
                        </b>
                        <p>{{ nuevo.unidad }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15">
                        <b class="mr-2 text-general" text-general>
                            Venta mínima:
                        </b>
                        <p>{{ nuevo.cantidad_minima }} {{ nuevo.unidad }}.</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general">
                        <b class="mr-2 f-600">
                            Cantidad Pedida:
                        </b>
                        <p>{{ nuevo.cantidad_pedida }}</p>
                    </div>
                    <div v-show="mensaje_eliminado != ''" class="row mx-0 my-2 f-18 f-600 text-gr-red justify-center">
                        <span>{{ mensaje_eliminado }}</span>
                    </div>
                    <hr v-show="estado=='editado'" />
                    <div v-show="estado=='editado'" class="row mx-0 my-2 f-15 text-gr-red justify-center">
                        <b class="mr-2 text-general">
                            Cantidad Inicial:
                        </b>
                        <p>{{ antiguo.cantidad_inicial }}</p>
                    </div>


                    <div v-show="estado=='desconocido'" class="">
                        <hr />
                        <div class="row mx-0 my-2 f-14 justify-center">
                            Producto Inicial
                        </div>
                        <div class="row mx-0 my-2 justify-center">
                            <img class="border bg-whitesmoke br-5" src="/img/no-imagen/question-mark.svg" width="90" height="90" alt="" />
                        </div>
                        <div class="row mx-0 my-2 f-600 f-14 justify-center">
                            Producto Desconocido
                        </div>

                        <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                            <div class="col-10 text-center">
                                {{ desconocido.descripcion }}
                            </div>
                        </div>
                    </div>


                    <div v-show="estado=='cambio'" class="">
                        <div class="row mx-0 my-2 f-14 justify-center">
                            Producto Inicial
                        </div>
                        <div class="row mx-0 my-2 justify-center">
                            <img class="border bg-whitesmoke br-5 p-2" :src="antiguo.imagen" width="90" height="90" alt="" />
                        </div>
                        <div class="row mx-0 my-2 f-600 f-14 justify-center">
                            {{ antiguo.nombre }}
                        </div>
                        <div class="row mx-0 my-2 f-600 f-14 justify-center">
                            {{ separadorNumero(antiguo.precio,$tienda.decimales) }}
                        </div>
                        <div class="row mx-0 my-2 mt-3 f-15 justify-center">
                            <div class="col-10 text-center">
                                {{ antiguo.descripcion }}
                            </div>
                        </div>
                        <div class="row mx-0 my-2 f-15 justify-center">
                            <b class="mr-2">
                                Presentación:
                            </b>
                            <p>{{ antiguo.presentacion }}</p>
                        </div>
                        <div class="row mx-0 my-2 f-15 justify-center">
                            <b class="mr-2">
                                Unidad de medida:
                            </b>
                            <p>{{ antiguo.unidad }}</p>
                        </div>
                        <div class="row mx-0 my-2 f-15 justify-center">
                            <b class="mr-2">
                                Venta mínima:
                            </b>
                            <p>{{ antiguo.cantidad_minima }} {{ antiguo.unidad }}.</p>
                        </div>
                        <div class="row mx-0 my-2 f-15 text-gr-red justify-center">
                            <b class="mr-2">
                                Cantidad pedida:
                            </b>
                            <p>{{ antiguo.cantidad_pedida }}</p>
                        </div>
                    </div>
                </div>
                <div v-show="nuevo.kit" class="col pr-0">
                    <productos-kits ref="detalleKit" />
                </div>
            </div>
        </modal>
        <modal-imagenes ref="modalImagnes" @cerrar="$refs.modalDetalleProducto.toggle()" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-tienda'
import Productos from '~/services/productos/productos'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            estado: '',
            nuevo:{nombre:''},
            antiguo:{},
            desconocido:{},
            datos:{},
            mensaje_eliminado:''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
        }),
    },
    methods: {
        async datos_cambio(info){
            try {
                this.estado = ''
                this.mensaje_eliminado = ''

                const {data} = await Pedidos.producto_cambiado(info.id)
                this.datos = info

                console.log(info);

                if(info.kit){
                    await this.$refs.detalleKit.getProductosKit(this.datos.producto.id)
                }

                let decimalesFrancion = 0
                if(info.producto.cantidad_tipo === 1){
                    decimalesFrancion = 0
                } else{
                    decimalesFrancion = 2
                }

                this.nuevo = {
                    id:info.producto.id,
                    nombre:info.producto.nombre,
                    descripcion:info.producto.descripcion,
                    imagen:info.producto.imagen,
                    precio: info.unidad_final,
                    presentacion:info.producto.presentacion,
                    unidad:info.producto.unidad,
                    cantidad_minima:this.agregarSeparadoresNumero(info.producto.cantidad_minima,decimalesFrancion),
                    cantidad_pedida: this.agregarSeparadoresNumero(info.cantidad,decimalesFrancion) + info.producto.unidad + ': ' + this.separadorNumero(info.total_final,this.$tienda.decimales),
                    kit:info.kit
                }

                if(data.original){
                    if(data.eliminado){
                        this.mensaje_eliminado = 'Producto Eliminado del Pedido'
                    }
                    this.$refs.modalDetalleProducto.toggle()

                    return
                }
                if(data.inicial.cambio){
                    if(data.inicial.desconocido){
                        this.estado = 'desconocido'
                        this.desconocido = {
                            descripcion:data.inicial.nombre
                        }

                    }else{
                        this.estado = 'cambio'
                        this.antiguo = {
                            id:data.inicial.id,
                            nombre:data.inicial.nombre,
                            descripcion:data.inicial.descripcion,
                            imagen:data.inicial.imagen,
                            precio: data.inicial.unidad_final,
                            presentacion:data.inicial.presentacion,
                            unidad:data.inicial.unidad,
                            cantidad_minima:this.agregarSeparadoresNumero(data.inicial.cantidad_minima,2),
                            cantidad_pedida: this.agregarSeparadoresNumero(data.inicial.cantidad,2) + data.inicial.unidad + ': ' + this.separadorNumero(data.inicial.total_final,this.$tienda.decimales)

                        }
                    }
                }else{
                    this.estado = 'editado'
                    this.antiguo = {
                        cantidad_inicial: this.agregarSeparadoresNumero(data.inicial.cantidad,2) + info.producto.unidad + ': ' + this.separadorNumero(data.inicial.total_final,this.$tienda.decimales)
                    }
                }

                this.$refs.modalDetalleProducto.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async getImages(){

            const {data} = await Productos.getImages(this.datos.producto.id)
            if(data.imagenes.length === 0) return
            this.$refs.modalDetalleProducto.toggle()
            this.$refs.modalImagnes.toggle(data.imagenes)
        },
    }
}
</script>
